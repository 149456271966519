<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="3"
      >
        <statistics-card-area
          :statistic="summaryDatas.totalRecordNum"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardTotalRecords')"
          :color="'#A9A2F6'"
          :chart-data="statisticsTotalRecords"
          :chart-days="lastSevenDays"
          icon="mdi-credit-card-scan-outline"
          icon-color="deep-purple lighten-1"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <statistics-card-area
          :statistic="summaryDatas.warnRecordNum"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardWarnRecords')"
          :color="'#ffc085'"
          :chart-data="statisticsWarnRecords"
          :chart-days="lastSevenDays"
          icon="mdi-credit-card-remove-outline"
          icon-color="warning lighten-1"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <statistics-card-area
          :statistic="summaryDatas.totalPersonNum"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardTotalPersons')"
          :color="'#64B5F6'"
          :chart-data="statisticsTotalPersons"
          :chart-days="lastSevenDays"
          icon="mdi-account-multiple-outline"
          icon-color="blue lighten-1"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <statistics-card-area
          :statistic="summaryDatas.warnPersonNum"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardWarnPersons')"
          :color="'#F97794'"
          :chart-data="statisticsWarnPersons"
          :chart-days="lastSevenDays"
          icon="mdi-account-multiple-remove-outline"
          icon-color="red lighten-1"
        />
      </v-col>
      <v-col
        cols="8"
      >
        <tendency-card-line
          :total-value="summaryDatas.totalRecordNum"
          :part-value="summaryDatas.warnRecordNum"
          :total-records="statisticsTotalRecords"
          :warn-records="statisticsWarnRecords"
          :chart-days="lastSevenDays"
          :total-label="$vuetify.lang.t('$vuetify.temperature.dashboardTotalRecords')"
          :part-label="$vuetify.lang.t('$vuetify.temperature.dashboardWarnRecords')"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardWarnRecordsTendency')"
          color="#FFCC80"
          color-to="#FF9800"
        />
      </v-col>
      <v-col
        cols="4"
      >
        <statistics-card-radial-bar
          :total-value="summaryDatas.totalRecordNum"
          :part-value="summaryDatas.warnRecordNum"
          :total-label="$vuetify.lang.t('$vuetify.temperature.dashboardTotalRecords')"
          :part-label="$vuetify.lang.t('$vuetify.temperature.dashboardWarnRecords')"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardWarnRecordsRatio')"
          color="#FFCC80"
          color-to="#FF9800"
        />
      </v-col>
      <v-col
        cols="4"
      >
        <statistics-card-radial-bar
          :total-value="summaryDatas.totalPersonNum"
          :part-value="summaryDatas.warnPersonNum"
          :total-label="$vuetify.lang.t('$vuetify.temperature.dashboardTotalPersons')"
          :part-label="$vuetify.lang.t('$vuetify.temperature.dashboardWarnPersons')"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardWarnPersonsRatio')"
        />
      </v-col>
      <v-col
        cols="8"
      >
        <tendency-card-bar
          :total-value="summaryDatas.totalPersonNum"
          :part-value="summaryDatas.warnPersonNum"
          :total-records="statisticsTotalPersons"
          :warn-records="statisticsWarnPersons"
          :chart-days="lastSevenDays"
          :total-label="$vuetify.lang.t('$vuetify.temperature.dashboardTotalPersons')"
          :part-label="$vuetify.lang.t('$vuetify.temperature.dashboardWarnPersons')"
          :statistic-title="$vuetify.lang.t('$vuetify.temperature.dashboardWarnPersonsTendency')"
          color="#FFCC80"
          color-to="#FF9800"
        />
      </v-col>
      <v-col
        v-if="regionLevel"
        cols="7"
      >
        <statistics-card-map
          :region-level="regionLevel"
          :region-id="regionId"
          :monitor-datas="monitorMapDatas"
        />
      </v-col>
      <v-col
        :cols="regionLevel ? '5' : '12'"
      >
        <v-card
          class="fill-height"
        >
          <v-card-title
            class="pb-1 b"
          >
            {{ $vuetify.lang.t('$vuetify.temperature.dashboardDataList') }}
            <v-spacer></v-spacer>
            <v-btn
              v-if="lastDepCode"
              class="mx-2"
              color="primary"
              dark
              x-small
              text
              @click="handleTableGoBack"
            >
              {{$vuetify.lang.t('$vuetify.common.goBack')}}
            </v-btn>
            <v-icon
              v-else
              class="text--disabled"
            >
              mdi-help-circle-outline
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{$vuetify.lang.t('$vuetify.temperature.dashboardRegion')}}</th>
                    <th class="text-left">{{$vuetify.lang.t('$vuetify.temperature.dashboardTotalRecords')}}</th>
                    <th class="text-left">{{$vuetify.lang.t('$vuetify.temperature.dashboardWarnRecords')}}</th>
                    <th class="text-left">{{$vuetify.lang.t('$vuetify.temperature.dashboardTotalPersons')}}</th>
                    <th class="text-left">{{$vuetify.lang.t('$vuetify.temperature.dashboardWarnPersons')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in monitorDatas"
                    :key="item.institutionId"
                    @click="handleTableClick(item)"
                  >
                    <td
                      class="blue--text"
                    >
                      {{ item.regionName }}
                    </td>
                    <td>{{ item.totalRecordNum }}</td>
                    <td>{{ item.warnRecordNum }}</td>
                    <td>{{ item.totalPersonNum }}</td>
                    <td>{{ item.warnPersonNum }}</td>

                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'TemperatureCity',

    components: {
      StatisticsCardArea: () => import('./statistics-cards/StatisticsCardArea'),
      StatisticsCardMap: () => import('./statistics-cards/StatisticsCardMap'),
      StatisticsCardRadialBar: () => import('./statistics-cards/StatisticsCardRadialBar'),
      TendencyCardBar: () => import('./statistics-cards/TendencyCardBar'),
      TendencyCardLine: () => import('./statistics-cards/TendencyCardLine')
    },

    data () {
      return {
        timer: null,
        statisticsTotalRecords: [],
        statisticsWarnRecords: [],
        statisticsTotalPersons: [],
        statisticsWarnPersons: [],
        monitorMapDatas: [],
        depCodeStack: [],
        lastDepCode: null
      }
    },

    computed: {
      ...mapGetters({
        summaryDatas: 'temperatureMonitor/summaryDatas',
        trendsDatas: 'temperatureMonitor/trendsDatas',
        monitorDatas: 'temperatureMonitor/monitorDatas',
        user: 'auth/user'
      }),

      lastSevenDays () {
        let res = new Array(7).fill().map( (_, index) => {
          return this.$moment().subtract(index, 'day').format("YYYY-MM-DD")
        })
        return res.reverse()
      },

      regionLevel() {
        let level = null
        if (this.user && this.user.profile && this.user.profile.regionLevel === 'CITY') {
          level = this.user.profile.regionLevel
        }
        return level
      },

      regionId() {
        if (this.user && this.user.profile && this.user.profile.regionId) {
          return this.user.profile.regionId
        } else {
          return null
        }
      }


    },

    mounted() {
    },

    created () {
      this.initialize()
    },

    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },


    methods: {

      ...mapActions({
        queryTemperatureTrendsData: 'temperatureMonitor/queryTemperatureTrendsData',
        queryTemperatureSummaryData: 'temperatureMonitor/queryTemperatureSummaryData',
        queryTemperatureMonitorData: 'temperatureMonitor/queryTemperatureMonitorData'
      }),

      initialize () {
        this.timeTask()
        this.timer = setInterval(() => {
          this.timeTask()
        }, 60000)
      },

      timeTask() {
        let data = {}
        this.queryTemperatureTrendsData(data).then( (datas) => {
          let totalRecordsTmp = new Array(7).fill(0)
          let warnRecordsTmp = new Array(7).fill(0)
          let totalPersonsTmp = new Array(7).fill(0)
          let warnPersonsTmp = new Array(7).fill(0)

          datas.forEach( element => {
            let index = this.lastSevenDays.findIndex( ele => ele === element.targetDate)
            if ( index !== -1 ) {
              totalRecordsTmp[index] = element.totalRecordNum
              warnRecordsTmp[index] = element.warnRecordNum
              totalPersonsTmp[index] = element.totalPersonNum
              warnPersonsTmp[index] = element.warnPersonNum
            }
          })
          this.statisticsTotalRecords = totalRecordsTmp
          this.statisticsWarnRecords = warnRecordsTmp
          this.statisticsTotalPersons = totalPersonsTmp
          this.statisticsWarnPersons = warnPersonsTmp
        })
        this.queryTemperatureSummaryData(data)
        this.loadTemperatureMonitorData()
      },

      loadTemperatureMonitorData() {
        let data = {}
        if (this.lastDepCode) {
          data.depCode = this.lastDepCode
        }
        this.queryTemperatureMonitorData(data).then( () => {
          if (!this.lastDepCode) {
            this.monitorMapDatas = this.monitorDatas
          }
        })
      },

      handleTableClick(item) {
        if(item.depCode && this.lastDepCode !== item.depCode) {
          this.depCodeStack.push(this.lastDepCode)
          this.lastDepCode = item.depCode
          this.loadTemperatureMonitorData()
        }
      },

      handleTableGoBack() {
        if (this.depCodeStack.length > 0) {
          this.lastDepCode =  this.depCodeStack.pop()
        } else {
          this.lastDepCode = null
        }
        this.loadTemperatureMonitorData()
      }
    }
  }
</script>
